<template>
  <div>
    <master-component :config="config"></master-component>
  </div>
</template>


<script>
import { UserRole, InputType, DialogSize } from "./../js/constants";
import { InputRelate } from "./../js/custom";

import { fakeAbbr, getFakeUrl } from "./../_helpers/fake-url";

export default {
  data() {
    return {};
  },
  created() {
    const config = this.config;
  },
  computed: {
    config() {
      return {
        name: "master-province",
        shortName: "จังหวัด",
        search: this.searchSection,
        form: this.formSection,
        table: this.tableSection,
        permission: this.permission,
        url: this.urlSection
      };
    },
    urlSection() {
      let searchUrl = "/province/search";
      searchUrl = getFakeUrl(fakeAbbr.province.search) + searchUrl;

      let getUrl = "/province";
      getUrl = getFakeUrl(fakeAbbr.province.get) + getUrl;

      let addUrl = "/province";
      addUrl = getFakeUrl(fakeAbbr.province.create) + addUrl;

      let editUrl = "/province";
      editUrl = getFakeUrl(fakeAbbr.province.update) + editUrl;

      let removeUrl = "/province";
      removeUrl = getFakeUrl(fakeAbbr.province.remove) + removeUrl;

      return {
        search: searchUrl,
        get: getUrl,
        add: addUrl,
        edit: editUrl,
        remove: removeUrl
      };
    },
    searchSection() {
      return {
        rows: [
          [
            {
              name: "name",
              text: "Name",
              type: InputType.text
            },
            {
              type: InputType.offset
            },
            {
              type: InputType.offset
            }
          ]
        ]
      };
    },
    permission() {
      return {
        add: [UserRole.ADMIN],
        edit: [UserRole.ADMIN],
        remove: [UserRole.ADMIN]
      };
    },
    tableSection() {
      return {
        columns: [
          {
            name: "thaiName",
            text: "Thai Name"
          },
          {
            name: "englishName",
            text: "englishName"
          },
          {
            name: "code",
            text: "Code"
          }
        ]
      };
    },
    formSection() {
      return {
        size: DialogSize.medium,
        rows: [
          [
            {
              name: "code",
              text: "Code",
              type: InputType.text
            },
            {
              name: "thaiName",
              text: "Thai Name",
              type: InputType.text,
              rule: {
                required: true
              }
            },
            {
              name: "englishName",
              text: "English Name",
              type: InputType.text,
              rule: {
                required: true
              }
            }
          ]
        ]
      };
    }
  }
};
</script>
